
<!--提报记录：提报记录-->
<template>
  <div class="app-container">
    <!--页头-->
    <div class="page-container">
      <div class="section">
        <div class="control-box">
          <div class="cb-left">
            <h1 style="font-size: 20px;">{{ $t('Submission_Record') }}</h1>
          </div>
        </div>
        <div class="table-box">
          <el-tabs v-model="activeName" @tab-click="getDeviceListById">
            <el-tab-pane v-if="roleType !== 11" :label="$t('replacement')" :name="roleType !== 11 ? 'first' : ''">
              <div style="text-align:right;padding-bottom:10px">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    @click="addTeamUserShow()"
                >
                  {{ $t('add') }}
                </el-button>
              </div>
                <div class="table-box">
                  <el-table
                      :data="replacementData"
                      border
                      highlight-current-row
                      style="width: 100%"
                  >
                    <el-table-column :label="$t('deviceName')" align="center">
                      <template slot-scope="scope">
                        {{ scope.row.name }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('replacement_item')" align="center">
                      <template slot-scope="scope">
                        {{ scope.row.project}}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('submit_time')" align="center">
                      <template slot-scope="scope">
                        {{scope.row.date}}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('device_state')" align="center">
                      <template slot-scope="scope">
                        <span style="font-weight: bold;" :style="{color: scope.row.result === '1' ? 'green' : 'grey' }">
                {{scope.row.result === '0' ? $t('unreviewed') : scope.row.result === '2' ? $t('rejected') : scope.row.result === '1' ? $t('approved') : $t('overtime_cancellation')}}
              </span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('remark')" align="center">
                      <template slot-scope="scope">
                          {{scope.row.remark}}
                      </template>
                    </el-table-column>
                    <!--操作-->
                    <el-table-column :label="$t('caozuo')" header-align="center" align="center" width="240">
                      <template slot-scope="scope">
                        <el-button type="primary"  size="mini" @click="checkReplacement(scope.row,2)">
                          {{ $t('device_view') }}
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <!--分页-->
                <div class="pagination-box">
                  <el-pagination
                      :page-sizes="[10, 20, 30, 40]"
                      :page-size="replacement.count"
                      :total="replacementCount"
                      :current-page="replacement.page"
                      layout="total, sizes, prev, pager, next, jumper"
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                  />
                </div>
            </el-tab-pane>
            <el-tab-pane v-if="roleType !== 11" :label="$t('exception_submission')" name="second">
                <div style="text-align:right;padding-bottom:10px">
                  <el-button
                      type="primary"
                      icon="el-icon-plus"
                      @click="addAbnormal()"
                  >
                    {{ $t('add') }}
                  </el-button>
                </div>
                <div class="table-box">
                  <el-table
                      :data="abnormal"
                      border
                      highlight-current-row
                      style="width: 100%"
                  >
                    <el-table-column :label="$t('deviceName')" align="center" width="300">
                      <template slot-scope="scope">
                        {{ scope.row.name }}
                      </template>
                    </el-table-column>
                    <!--性别-->
                    <el-table-column :label="$t('yichangTime')" align="center" width="120">
                      <template slot-scope="scope">
                        {{ scope.row.actualTime }}
                      </template>
                    </el-table-column>
                    <!--角色-->
                    <el-table-column :label="$t('submission_reason')" align="center">
                      <template slot-scope="scope">
                        <span>{{ scope.row.reason }}</span>
                      </template>
                    </el-table-column>
                    <!--<el-table-column :label="$t('material_reason')" align="center">
                      <template slot-scope="scope">
                        <span>{{ scope.row.materialReason }}</span>
                      </template>
                    </el-table-column>-->
                    <!--手机号-->
                    <el-table-column :label="$t('reporter')" align="center" width="150">
                      <template slot-scope="scope">
                        <span>{{ scope.row.person }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="created_at" :label="$t('submit_time')" align="center" width="170">
                      <template slot-scope="scope">
                        <span>{{ scope.row.date }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="created_at" :label="$t('device_state')" align="center" width="150">
                      <template slot-scope="scope">
                        <span :style="{color:scope.row.state === 0 ? '#EA000E' : scope.row.state === 1 ? '#F7B515' : scope.row.state === 2 ? '#37C239' :'#777474' }">{{scope.row.state === 0 ? '未提报' : scope.row.state === 1 ? $t('unreviewed') : scope.row.state === 2 ? $t('reviewed') :$t('overtime_cancellation')}}</span>
                      </template>
                    </el-table-column>
                    <!--操作-->
                    <el-table-column :label="$t('caozuo')" align="center" width="170">
                      <template slot-scope="scope">
                        <el-button
                            type="primary"
                            size="mini"
                            @click="checkAbnormal(scope.row)"
                        >
                          {{ $t('device_view') }}
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <!--分页-->
                <div class="pagination-box">
                  <el-pagination
                      :page-sizes="[10, 20, 30, 40]"
                      :page-size="abnormalForm.count"
                      :total="abnormalCount"
                      :current-page="abnormalForm.page"
                      layout="total, sizes, prev, pager, next, jumper"
                      @size-change="handleSizeChange2"
                      @current-change="handleCurrentChange2"
                  />
                </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('fault_repair')" :name="roleType !== 11 ? 'three' : 'first'">
              <div style="text-align:right;padding-bottom:10px">
                <el-button
                    v-if="activeName === 'three' || roleType === 11 "
                    type="primary"
                    icon="el-icon-plus"
                    @click="addFault()"
                >
                  {{ $t('add') }}
                </el-button>
              </div>
              <!--表格-->
              <div class="table-box">
                <el-table
                    :data="faultList"
                    element-loading-text="Loading"
                    border
                    highlight-current-row
                    style="width: 100%"
                >
                  <!--昵称-->
                  <el-table-column :label="$t('deviceName')" align="center" >
                    <template slot-scope="scope">
                      {{ scope.row.name }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('repair_type')" align="center" width="150">
                    <template slot-scope="scope">
                      <span>{{ scope.row.type }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('repair_personnel')" v-if="UserInfo.teamId !== '6c448d56-de6e-48b5-9549-1f8a0022f0e5'" align="center" width="150">
                    <template slot-scope="scope">
                      <span>{{ scope.row.person }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="created_at" :label="$t('submit_time')" align="center" width="170">
                    <template slot-scope="scope">
                      <span>{{ scope.row.date }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="created_at" :label="$t('device_state')" align="center" width="150">
                    <template slot-scope="scope">
                      <span style="color:#37C239;" v-if="scope.row.state === 0 && UserInfo.teamId !== '6c448d56-de6e-48b5-9549-1f8a0022f0e5'">{{ $t('assigned') }}</span>
                      <span style="color:#F7B515;" v-if="scope.row.state === 0 && UserInfo.teamId === '6c448d56-de6e-48b5-9549-1f8a0022f0e5'">{{ $t('pending_confirmation') }}</span>
                      <span style="color:#EA000E;" v-if="scope.row.state === 1">{{ $t('pending_start') }}</span>
                      <span style="color:#409eff;" v-if="scope.row.state === 2">{{ $t('in_progress') }}</span>
                      <span style="color:#F7B515;" v-if="scope.row.state === 3">{{ $t('pending_confirmation') }}</span>
                      <span style="color:#666666;" v-if="scope.row.state === 4">{{ $t('finish') }}</span>
                      <span style="color:#999999;" v-if="scope.row.state === 5">{{ $t('cancelled') }}</span>
                      <span style="color:#EA000E;" v-if="scope.row.state === 6">{{ $t('Timed_Out') }}</span>
                      <span style="color:#999999;" v-if="scope.row.state === 7">{{ $t('Suspended') }}</span>
                    </template>
                  </el-table-column>
                  <!--操作-->
                  <el-table-column :label="$t('caozuo')" align="center" width="170">
                    <template slot-scope="scope">
                      <el-button
                          type="primary"
                          size="mini"
                          @click="checkFault(scope.row)"
                      >
                        {{ $t('device_view') }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!--分页-->
              <div class="pagination-box">
                <el-pagination
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="faultForm.count"
                    :total="faultCount"
                    :current-page="faultForm.page"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange3"
                    @current-change="handleCurrentChange3"
                />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!--弹出框：添加定期更换-->
    <el-dialog
        :visible.sync="TeamUserShow"
        :title="$t('Add_regular_replacement')"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :append-to-body="false"
        width="30%"
        @close="close1()"
    >
      <el-form label-position="left" ref="inviteForm" label-width="110px" :model="invite" :rules="rules.inviteRules">
        <el-form-item :label="$t('deviceName')" prop="deviceId">
          <el-select
              v-model="invite.deviceId"
              :placeholder="$t('xuanzeDevice')"
              style="width: 100%"
          >
            <el-option
                v-for="item in deviceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('replacement_item')" prop="project">
          <el-select
              v-model="invite.project"
              :placeholder="$t('please_select_replacement_project')"
              style="width: 100%"
          >
            <el-option
                v-for="item in reasonList"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('remark')" prop="remark">
          <el-input
              v-model="invite.remark"
              show-word-limit
              clearable
              :placeholder="$t('Please_fill_remarks')"
              maxlength="100"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="TeamUserShow = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" :loading="addUserBtnLoading" @click="addTeamUser()">
          {{ $t('submit') }}
        </el-button>
      </div>
    </el-dialog>
    <!--弹出框：添加异常提报-->
    <el-dialog
        :visible.sync="abnormalShow"
        :title="$t('exception_reporting')"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :append-to-body="false"
        width="30%"
        @close="close2()"
    >
      <el-form label-position="left" ref="abnormalRules" label-width="140px" :model="abnormalData" :rules="rules.abnormalRules">
        <el-form-item :label="$t('deviceName')" prop="deviceId">
          <el-select
              v-model="abnormalData.deviceId"
              :placeholder="$t('xuanzeDevice')"
              style="width: 100%"
              @change="handleDevice"
          >
            <el-option
                v-for="item in abnormalDeviceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('edingTime')" prop="phone">
          <span>{{ratedTime}}</span>
        </el-form-item>
        <el-form-item :label="$t('startTime')" prop="startTime">
          <el-date-picker
              v-model="abnormalData.startTime"
              :picker-options="pickerOptions"
              type="datetime"
              @change="handleChangeStart"
              :placeholder="$t('date_and_time')">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('endTime')" prop="endTime">
          <el-date-picker
              v-model="abnormalData.endTime"
              :picker-options="pickerOptionsEnd"
              type="datetime"
              @change="handleChangeEnd"
              :placeholder="$t('date_and_time')">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('working_hours')" prop="workTime">
          <el-input-number
              v-model="abnormalData.workTime"
              show-word-limit
              :max="1000000000000"
              :min="1"
              :precision="0"
              :placeholder="$t('enter_working_hours')"
          />
          <span style="margin-left: 10px">{{ $t('min') }}</span>
        </el-form-item>
        <el-form-item :label="$t('yichangTime')" prop="actualTime">
          <el-input-number
              v-model="abnormalData.actualTime"
              show-word-limit
              :max="1000000000000"
              :min="1"
              :precision="0"
              :placeholder="$t('enter_abnormal_standby_time')"
          />
          <span style="margin-left: 10px">{{ $t('min') }}</span>
        </el-form-item>
        <el-form-item :label="$t('submission_reason')" prop="reason">
          <el-button  type="primary" size="mini"
                      v-if="abnormalData.reason && !abnormalData.reason.includes('无单待机')"
                      style="position:absolute;top:-35px;right:0" @click.stop="handleTime">{{ $t('Time_Equalization') }}
          </el-button>
          <el-select
              v-model="abnormalData.reason"
              multiple
              :placeholder="$t('please_select_submission_reason')"
              style="width: 100%"
              @change="handleChange"
          >
            <el-option
                v-for="item in abnormalReasonList"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
        <div v-for="(item,index) in ReasonReporting" :key="index">
          <el-form-item :label="$t(item.reason2)" prop="" v-if="item.reason2 === '材料原因'">
            <el-select style="width: 100%" v-model="item.materialReason" clearable :disabled="abnormalData.materialReason2 ? true : false"  :placeholder="$t('please_select')">
              <el-option
                  v-for="item in contentMaterialList"
                  :key="item"
                  :label="item"
                  :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t(item.reason2)" prop="">
            <el-input-number
                v-model="item.time"
                show-word-limit
                :max="1000000000000"
                :min="0"
                :precision="0"
                :placeholder="$t('enter_working_hours')"
            />
            <span style="margin-left: 10px">{{ $t('min') }}</span>
          </el-form-item>
        </div>
        <!--<el-form-item :label="$t('material_reason')" prop="materialReason" v-if="abnormalData.reason && abnormalData.reason.includes('材料原因')">
         <el-select style="width: 100%" v-model="abnormalData.materialReason" clearable :disabled="abnormalData.materialReason2 ? true : false"  :placeholder="$t('please_select')">
           <el-option
               v-for="item in contentMaterialList"
               :key="item"
               :label="item"
               :value="item"
           ></el-option>
         </el-select>
       </el-form-item>
     <div ="itemCost" v-for="(item,index) in ReasonReporting" :key="index">
         <div class="titleCost">{{ item.reason2 }}</div>
         <div class="contentCost mt10 mb10" v-if="item.reason2 === '材料原因'">
           <van-field
               class="contentCost-input"
               readonly
               clickable
               label=""
               :value="item.materialReason"
               :placeholder="$t('please_select_material_reason')"
               @click="showPickerMaterial = true"
               right-icon="arrow-down"
           />
         </div>
         <div class="contentCost">
           <van-field class="contentCost-input"
                      @input="adjustAmountValue($event, 1000000000000, 0,index)"
                      v-model="item.time" type="number" label="" :placeholder="$t('Please_enter_the_duration')"/>
           <p class="contentCost-unit">{{ $t('min') }}</p>
         </div>
       </div>-->
        <el-form-item :label="$t('remark')" prop="">
          <el-input
              v-model="abnormalData.remark"
              show-word-limit
              clearable
              :placeholder="$t('Please_fill_remarks')"
              maxlength="100"
          />
          <!--     size="medium"       -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="abnormalShow = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" :loading="addUserBtnLoading" @click="addAbnormalBtn()">
          {{ $t('submit') }}
        </el-button>
      </div>
    </el-dialog>
    <!--弹出框：添加故障报修-->
    <el-dialog
        :visible.sync="faultShow"
        :title="$t('fault_report')"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :append-to-body="false"
        width="30%"
        @close="close1()"
    >
      <el-form label-position="left" ref="faultRules" label-width="110px" :model="faultDate" :rules="rules.faultRules">
        <el-form-item :label="$t('deviceName')" prop="deviceId">
          <el-select
              v-model="faultDate.deviceId"
              :placeholder="$t('xuanzeDevice')"
              clearable
              style="width: 100%"
          >
            <el-option
                v-for="item in deviceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('fault_type')" prop="project">
          <el-select
              v-model="faultDate.project"
              :placeholder="$t('please_select_fault_type')"
              style="width: 100%"
              clearable
          >
            <el-option
                v-for="item in repairTypes"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('repair_personnel')" v-if="UserInfo.teamId !== '6c448d56-de6e-48b5-9549-1f8a0022f0e5'" class="person" prop="">
          <span class="btn" v-if="!faultDate.name" @click="handlePerson">{{ $t('Optional_tian') }}<i class="el-icon-arrow-right"></i></span>
          <span class="btn" v-else style="color:#606266" @click="handlePerson">{{ faultDate.name }}<i class="el-icon-arrow-right"></i></span>
        </el-form-item>
        <el-form-item label="" v-if="faultDate.deviceId" class="record" prop="">
          <div class="history" @click="goHistory">
            <img src="@/assets/img/icon-device.png" :alt="$t('historical_repair_records')" />
            <span>{{ $t('historical_repair_records') }}</span>
          </div>
        </el-form-item>
        <el-form-item :label="$t('fault_issue')" prop="repairInfo">
          <el-input
              v-model="faultDate.repairInfo"
              show-word-limit
              clearable
              type="textarea"
              :rows="4"
              :placeholder="$t('please_enter_fault_issue')"
              maxlength="150"
          />
        </el-form-item>
        <div class="pic-and-video-wrapper">
          <div v-for="(img, index) in pics" :key="index" class="preview">
            <el-image
                style="width: 100%"
                :src="img"
                :preview-src-list="pics">
            </el-image>
            <el-button class="van-btton" icon="el-icon-delete-solid" type="text"  @click="removeImage(index)" />
          </div>
          <div class="btn" @click="selectImage" v-show="pics.length < 3">
            <img src="@/assets/img/add-pic.png" alt="add pic" />
            <span style="font-size: 12px;">{{ $t('add_img') }}</span>
            <input v-show="false" ref="imgInput" type="file" accept="image/*" onclick="event.cancelBubble = true"
                   multiple @input="pickImage" />
          </div>
          <div class="btn" v-if="!!video">
            <span @click="playVideo">{{ $t('click_play') }}</span>
            <div class="closeVideo-btn" @click="removeVideo">
              <img src="@/assets/img/img_2.png" />
            </div>
          </div>
          <div class="btn" @click="selectVideo" v-show="!video">
            <img src="@/assets/img/add-video.png" alt="add video" />
            <span style="font-size: 12px;">{{ $t('Add_Video') }}</span>
            <input v-show="false" ref="videoInput" type="file" accept="video/*" onclick="event.cancelBubble = true"
                   multiple @input="pickVideo" />
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="faultShow = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" :loading="addUserBtnLoading" @click="addFaultBtn()">
          {{ $t('submit') }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 播放弹出框 -->
    <el-dialog :visible.sync="showVideo"
               :close-on-click-modal="false"
               :modal-append-to-body="false"
               :append-to-body="false"
               @close="close2()">
      <video ref="videoShow" style="width:100%;height:100%" controls>
        <source :src="videoSrc" type="video/mp4">。
      </video>
    </el-dialog>
<!--  人员选择  -->
    <el-dialog :visible.sync="showPerson"
               :title="$t('personnel_selection')"
               :close-on-click-modal="false"
               :modal-append-to-body="false"
               :append-to-body="false"
               width="30%"
               @close="close3()">
      <div>
        <div v-for="(item,index) in teamList" :key="index">
          <div v-if="item.orderType === '1'" class="item" @click="handlePersonSelect(item)">
            <div style="display: flex; align-items: center;">
              <img src="@/assets/img/icon-pen.png" alt="">
              <span>{{ item.cName }}</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
<!--  历史维修记录  -->
    <el-dialog :visible.sync="historyShow"
               :title="$t('historical_repair_records')"
               :close-on-click-modal="false"
               :modal-append-to-body="false"
               :append-to-body="false"
               width="30%"
               @close="close4()">
      <div>
        <div v-for="(item,index) in historyList" :key="index">
         <div class="list-item">
            <div class="item-left">
              <div>
                <img src="@/assets/img/icon-tool.png" alt="">
              </div>
              <div>
                <div class="name">{{item.device}}</div>
                <div>{{ $t('repair_personnel') }}:{{item.repairPerson}}</div>
              </div>
            </div>
           <div class="item-right">
             <div class="name">{{item.repairType}}</div>
             <div>{{item.repairTime}}</div>
           </div>
         </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from '@/api';
import { base64toFile } from '@/utils/file';
import moment from "moment";

export default {
  name: "reportList",
  data() {
    return {
      historyList: [],
      historyShow:false,
      showPerson:false,// 人员选择弹窗
      abnormal: [],// 异常提报
      abnormalShow:false,
      contentMaterialList: [
        this.$t('Printing_Plates'),this.$t('Paper'), this.$t('Ink'), this.$t('Rubber_Rollers'), this.$t('Blanket'), this.$t('Fountain_Solution'), this.$t('Powder'), this.$t('Varnish')
      ],
      abnormalForm: {
        page: 1,
        count: 10,
        type: 2
      },
      abnormalData: {
        groupId:'',
        info: '',
        workTime: 0,
        actualTime: 0,
        deviceId: '',
        ratedTime: '',
        startTime: '',
        endTime: '',
        remark: '',
        reason: '',
        reason2: '',
        materialReason: '',
        materialReason2: '',
        remark2: ''
      },
      startTime: '',
      endTime: '',
      abnormalDeviceList: [],
      abnormalReasonList: [],
      abnormalCount: 0,
      ratedTime: '',
      replacementData: [],// 定期更换
      loading:false,
      replacement: {
        page: 1,
        count: 10,
        type: 1
      },
      replacementCount: 0,
      TeamUserShow: false,
      addUserBtnLoading: false,
      invite: {
        deviceId: '',
        remark: '',
        project: ''
      },
      tabPaneTab: this.$t('replacement'),
      activeName: this.roleType !== 11 ? 'first' : 'three',
      deviceList:[],
      reasonList: [],
      totalCount: 0,
      faultList: [],// 异常提报
      faultShow: false,
      faultDate: {
        deviceId: '',// 设备
        repairInfo: '',// 问题
        orderType: 1, //
        project: '',
        userId: '', // 维修人员id
        name: '', // 维修人员名称
      },
      faultForm: {
        page: 1,
        count: 10,
        type: 3
      },
      faultCount: 0,
      pics: [],
      UserInfo: {},
      reasonValue: [],
      video: '',
      voice: null,
      showVideo: false,
      videoSrc: null,
      repairTypes: [this.$t('Electrical'), this.$t('Mechanical'), this.$t('Debugging'), this.$t('other')],
      teamList: [],
      ReasonReporting:[],
      pickerOptions: {
        disabledDate: time => {
          if (time) {
            this.startTime = this.dateFmt(time);
          }
          const year = new Date().getFullYear(); // 获取当前年份
          const januaryFirst = new Date(year, 0, 1); // 当年一月一号零时零点
          if (this.abnormalData.endTime) {
            return (time.getTime() > new Date(this.abnormalData.endTime).getTime() || time.getTime() < januaryFirst.getTime())
          } else {
            return time.getTime() < januaryFirst.getTime() || time.getTime() > Date.now(); // 禁用未来时间
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          if (time) {
            this.endTime = this.dateFmt(time);
          }
          if (this.abnormalData.startTime) {
            return (time.getTime() < new Date(this.abnormalData.startTime).getTime() || time.getTime() > Date.now())
          }else {
            const year = new Date().getFullYear(); // 获取当前年份
            const januaryFirst = new Date(year, 0, 1); // 当年一月一号零时零点
            return time.getTime() < januaryFirst.getTime() || time.getTime() > Date.now(); // 禁用未来时间
          }

        },
      },
      rules: {
        inviteRules: {
          deviceId: [{ required: true, message: this.$t('xuanzeDevice'), trigger: 'change' }],
          project: [{ required: true, message: this.$t('Please_select_Change_Directory'), trigger: 'change' }],
          remark: [{ required: true, trigger: 'blur', message: this.$t('Please_fill_remarks') }],
        },
        abnormalRules: {
          deviceId: [{ required: true, message: this.$t('xuanzeDevice'), trigger: 'change' }],
          startTime: [{ required: true, message: this.$t('select_start_time'), trigger: 'change' }],
          endTime: [{ required: true, message: this.$t('select_end_time'), trigger: 'change' }],
          workTime: [
            { required: true, trigger: 'blur', message: this.$t('enter_working_hours') },
            {
              pattern: /^\d+$/,
              message: '请输入正确的工作时长',
              trigger: 'blur'
            }
          ],
          reason: [{ required: true, message: this.$t('please_select_submission_reason'), trigger: 'change' }],
          materialReason: [{ required: true, message: '请选择材料原因', trigger: 'change' }],
          actualTime: [
            { required: true, trigger: 'blur', message: this.$t('enter_working_hours') },
            {
              pattern: /^\d+$/,
              message: '请输入正确的工作时长',
              trigger: 'blur'
            }
          ],

          remark: [{ required: true, trigger: 'blur', message: this.$t('Please_fill_remarks') }],
          // phone: [
          //   {
          //     required: true,
          //     trigger: 'blur',
          //     validator: (rule, value, callback) => {
          //       valid.checkNull(rule, value, callback, '请输入手机号', false);
          //       valid.checkByRegex(rule, value, callback, valid.REG_PHONE, '手机号不合法');
          //     },
          //   },
          // ],
          teamGroupId: [{ required: true, message: this.$t('please_select_team'), trigger: 'change' }],
          teamValues: [{ required: true, message: this.$t('please_select_team'), trigger: 'change' }],
        },
        faultRules: {
          deviceId: [{ required: true, message: this.$t('xuanzeDevice'), trigger: 'change' }],
          project: [{ required: true, message: this.$t('please_select_fault_type'), trigger: 'change' }],
          repairInfo: [{ required: true, trigger: 'blur', message: this.$t('please_enter_fault_issue') }],
        },
      },
      teamId: ''
    };
  },
  computed: {
    roleType() {

      return this.$store.state.userInfo.roleType
    }
  },
  created() {
    this.teamId = this.$store.state.userInfo.teamId
    this.getList()
    if (this.$store.state.reportForm.activeName === 'first') {
      this.tabPaneTab = this.$store.state.reportForm.tabPaneTab
      this.activeName = this.$store.state.reportForm.activeName
      this.replacement.page = this.$store.state.reportForm.page
      this.replacement.count = this.$store.state.reportForm.count
    } else if (this.$store.state.reportForm.activeName === 'second'){
      this.tabPaneTab = this.$store.state.reportForm.tabPaneTab
      this.activeName = this.$store.state.reportForm.activeName
      this.abnormalForm.page = this.$store.state.reportForm.page
      this.abnormalForm.count = this.$store.state.reportForm.count
    } else {
      this.tabPaneTab = this.$store.state.reportForm.tabPaneTab
      this.activeName = this.$store.state.reportForm.activeName
      this.faultForm.page = this.$store.state.reportForm.page
      this.faultForm.count = this.$store.state.reportForm.count
    }
    this.getTeamUser();
    this.getAbnormal()
    this.getFault()
    API.information()
        .then((res) => {
          if (res.message.success) {
            this.UserInfo = res.message.data
            // commit('SET_USERINFO', res.message.data);

          }
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
        });
    console.log(this.UserInfo,'99999')
  },
  mounted () {
    // console.log(this.$store.state)
    // console.log(this.$store.state.userInfo.userId)
  },
  methods: {
    getTeamUser() {
      API.getRecordList(this.replacement).then(res => {
        this.replacementData = res.message.data.list
        this.replacementCount = res.message.data.totalCount
      })
    },
    getList() {
      API.repairDeviceList().then(res => {
        this.deviceList.push(...res.message.data)
      })
      API.reasonList({type:14}).then((res) => {
        this.reasonList.push(...res.message.data)
      })
      API.abnormaDeviceList().then(res => {
        this.abnormalDeviceList.push(...res.message.data)
      })
      API.reasonList({type:13}).then((res) => {
        this.abnormalReasonList.push(...res.message.data)
      })
      this.teamList = []
      API.teamList({type:3}).then(res => {
        this.teamList.push(...res.message.data)
      })
    },
    getAbnormal() {
      API.getRecordList(this.abnormalForm).then((response) => {
        this.abnormal = response.message.data.list;
        this.abnormalCount = response.message.data.totalCount;
      });
    },
    getFault() {
      API.getRecordList(this.faultForm).then((response) => {
        this.faultList = response.message.data.list;
        this.faultCount = response.message.data.totalCount;
      });
    },
    handleDevice(e) {
      this.abnormalDeviceList.forEach(item => {
        if (item.id === e) {
          this.ratedTime = item.time + 'min'
          this.abnormalData.ratedTime = item.time
        }
      })
    },
    valueTime(value) {
      this.ReasonReporting = []
      const workStartHour = parseInt(this.UserInfo.teamTime.split(':')[0], 10); // 工作开始小时
      const workStartMinute = parseInt(this.UserInfo.teamTime.split(':')[1], 10); // 工作开始分钟
      const workEndHour = workStartHour; // 工作结束小时（第二天）
      const workEndMinute = workStartMinute; // 工作结束分钟（第二天）

      const startDate = new Date(this.abnormalData.startTime);
      const endDate = new Date(this.abnormalData.endTime);

      const result = [];

      // 处理开始日期
      let currentDate = new Date(startDate);

      // 计算开始日期的工作时长
      if (currentDate.getHours() < workStartHour ||
          (currentDate.getHours() === workStartHour && currentDate.getMinutes() < workStartMinute)) {
        // 如果开始时间在工作开始时间之前
        const hours = workStartHour - currentDate.getHours() - (currentDate.getMinutes() > 0 ? 1 : 0);
        const minutes = (60 - currentDate.getMinutes()) % 60;
        if (hours > 0 || minutes > 0) {
          result.push({
            date: new Date(currentDate.setHours(0, 0, 0, 0)).toISOString().split('T')[0],
            hours: hours,
            minutes: minutes
          });
        }
        currentDate.setHours(workStartHour, workStartMinute, 0, 0); // 设置为工作开始时间
      }

      // 处理完整的工作日
      while (currentDate < endDate) {
        const nextDay = new Date(currentDate);
        nextDay.setDate(currentDate.getDate() + 1);
        nextDay.setHours(workStartHour, workStartMinute, 0, 0); // 设置为下一个工作日的开始时间

        if (nextDay > endDate) {
          // 如果下一个工作日的开始时间超过结束时间
          const totalMinutes = Math.floor((endDate - currentDate) / (1000 * 60)); // 计算总分钟
          const hours = Math.floor(totalMinutes / 60);
          const minutes = totalMinutes % 60;
          result.push({
            date: new Date(currentDate).toISOString().split('T')[0],
            hours: hours,
            minutes: minutes
          });
          break;
        } else {
          // 完整的工作日
          result.push({
            date: new Date(currentDate).toISOString().split('T')[0],
            hours: 24,
            minutes: 0
          });
          currentDate = nextDay; // 移动到下一个工作日
        }
      }

      // 处理结束日期的工作时长
      if (endDate.getHours() < workEndHour ||
          (endDate.getHours() === workEndHour && endDate.getMinutes() < workEndMinute)) {
        const totalMinutes = Math.floor((endDate - new Date(endDate.setHours(workStartHour, workStartMinute))) / (1000 * 60));
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        if (hours > 0 || minutes > 0) {
          result.push({
            date: new Date(endDate).toISOString().split('T')[0],
            hours: hours,
            minutes: minutes
          });
        }
      } else {
        const totalMinutes = Math.floor((new Date(endDate.setHours(workEndHour, workEndMinute)) - new Date(endDate.setHours(workStartHour, workStartMinute))) / (1000 * 60));
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        if (hours > 0 || minutes > 0) {
          result.push({
            date: new Date(endDate).toISOString().split('T')[0],
            hours: hours,
            minutes: minutes
          });
        }
      }
      console.log(result)
      for (let i = 0; i< value.length; i++) {
        if (value[i] === '无单待机') {
          console.log(result)
          result.forEach(line => {
            this.ReasonReporting.push({
              reason: `无单待机`, // 动态生成日期
              reason2: `无单待机${line.date}`, // 动态生成日期
              materialReason: null,
              time: undefined,
              date: '', // 将日期从 list 中获
              orderDate: `${line.date}`
            });
          })
        } else {
          console.log(result)
          this.ReasonReporting.push({
            reason: value[i],
            reason2: value[i],
            materialReason: null,
            time:  undefined,
            date: '',
            orderDate: ''
          })
        }
      }

      // let sum = 0;
      // for (let i = 0; i < this.ReasonReporting.length - 1; i++) {
      //   sum += parseFloat(this.ReasonReporting[i].time); // 将 time 转换为数字并累加
      // }
      //
      // const lastIndex = this.ReasonReporting.length - 1;
      // this.ReasonReporting[lastIndex].time = (this.time - sum).toFixed(0);
      console.log(this.ReasonReporting)
    },
    handleTime() {
      let number = 0
      if (!this.ReasonReporting.includes('无单待机')) {
        this.ReasonReporting.forEach(line => {
          line.time = number !== 0 ? (this.abnormalData.actualTime / (this.abnormalData.reason.length + number - 1)).toFixed(0) : (this.abnormalData.actualTime / this.abnormalData.reason.length).toFixed(0)
        })
        let sum = 0;
        for (let i = 0; i < this.ReasonReporting.length - 1; i++) {
          sum += parseFloat(this.ReasonReporting[i].time); // 将 time 转换为数字并累加
        }
        const lastIndex = this.ReasonReporting.length - 1;
        this.ReasonReporting[lastIndex].time = (this.abnormalData.actualTime - sum).toFixed(0);
      }
    },
    handleChange(value) {
      console.log(value)
      this.reasonValue = value
      // console.log(this.content.reason)
      this.ReasonReporting = []
      this.valueTime(value)
    },
    handleChangeStart() {
      if (this.reasonValue.length > 0) {
        this.valueTime(this.reasonValue)
      }
    },
    handleChangeEnd () {
      if (this.reasonValue.length > 0) {
        this.valueTime(this.reasonValue)
      }
    },
    dateFmt(timestamp) {
      return moment(timestamp).format('MM-DD HH:mm')
    },
    dateFmtYear(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm')
    },
    // 查看
    checkReplacement(row) {
      this.$store.commit('reportFormSet', {page: this.replacement.page,count: this.replacement.count, tabPaneTab: this.tabPaneTab,activeName: this.activeName});
      this.$router.push({ path: 'replacementDetail', query: {id:row.id}});
    },
    checkAbnormal(row) {
      this.$store.commit('reportFormSet', {page: this.abnormalForm.page,count: this.abnormalForm.count, tabPaneTab: this.tabPaneTab,activeName: this.activeName});
      this.$router.push({ path: 'abnormalDetail', query: {id:row.id}});
    },
    checkFault(row) {
      this.$store.commit('reportFormSet', {page: this.faultForm.page,count: this.faultForm.count, tabPaneTab: this.tabPaneTab,activeName: this.activeName});
      this.$router.push({ path: 'faultDetailService', query: {deviceRepairId:row.id,title:this.$t('Repair_order_details')}});
    },
    addTeamUserShow() {
      this.invite = {
        deviceId: '',
        remark: '',
        project: ''
      }
      this.TeamUserShow = true;
      this.$nextTick(() => {
        // 清除验证
        this.$refs.inviteForm.clearValidate();
      });
    },
    close1() {
      this.TeamUserShow = false;
    },
    close2() {
      this.abnormalShow = false;
    },
    close3() {
      this.showPerson = false;
    },
    close4() {
      this.historyShow = false;
    },
    addAbnormal() {
      this.abnormalData = {
        groupId:'',
        info: '',
        workTime: 0,
        actualTime: 0,
        deviceId: '',
        ratedTime: '',
        startTime: '',
        endTime: '',
        remark: '',
        reason: ''
      }
      this.ratedTime = ''
      this.abnormalShow = true;
      this.$nextTick(() => {
        // 清除验证
        this.$refs.abnormalRules.clearValidate();
      });
    },
    addFault() {
      this.faultDate = {
        deviceId: '',// 设备
        repairInfo: '',// 问题
        orderType: 1, //
        project: '',
        userId: '', // 维修人员id
        name: '', // 维修人员名称
      }
      this.pics=[]
      this.video= ''
      this.voice= null
      this.showVideo= false
      this.videoSrc= null
      this.faultShow = true
      this.$nextTick(() => {
        // 清除验证
        this.$refs.faultRules.clearValidate();
      });
    },
    addTeamUser() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          this.addUserBtnLoading = true;
          API.submitReplacement(this.invite)
              .then((response) => {
                if (response.message.success) {
                  this.addUserBtnLoading = false;
                  this.$message({
                    message: response.message.message,
                    // showClose: true,
                    type: 'success',
                  });
                  this.TeamUserShow = false;
                  this.getTeamUser();
                } else if (!response.message.success) {
                  this.addUserBtnLoading = false;
                  // this.$message({
                  //   message: response.message.message,
                  //   // showClose: true,
                  //   type: 'error',
                  // });
                }
              })
              .catch(() => {
                this.addUserBtnLoading = false;
              });
        } else {
          return false;
        }
        return v;
      });
    },
    addAbnormalBtn() {
      const time1 = Date.parse(new Date(this.abnormalData.startTime))
      const time2 = Date.parse(new Date(this.abnormalData.endTime))
      if (time1 >= time2) {
        this.$message({
          message: this.$t('start_time_greater_end_time'),
          // showClose: true,
          type: 'warning',
        });
        return
      }
      let time = 0
      time = (time2 - time1) / 60000
      console.log(time)
      let number = 0
      let sumNumber = 0
      for (const item of this.ReasonReporting) {
        if (item.reason !== '无单待机') {
          if (!item.time || Number(item.time) === 0) {
            this.$message({type:'warning', message:this.$t('reason6')});
            return;
          }
        } else {
          sumNumber += Number(item.time)
          console.log(sumNumber)

        }
        if (item.reason === '材料原因' && !item.materialReason) {
          this.$message({type:'warning', message:'请选择材料原因'});
          return;
        }
        number += Number(item.time);
      }
      if (this.ReasonReporting.some(item => item.reason.includes('无单待机'))) {
        if (sumNumber === 0 || !sumNumber) {
          this.$message({type:'warning', message:'无单待机提报原因总时长不允许为空或等于0'});
          return;
        }
      }
      if (number !== Number(this.abnormalData.actualTime)) {
        this.$message({type:'warning', message:this.$t('reason1')});
        return;
      }
      let sum = Number(this.abnormalData.actualTime) + Number(this.abnormalData.workTime)
      if (time < sum){
        this.$message({type:'warning', message:this.$t('reason2')})
        return;
      }
      if ((this.abnormalData.reason.includes('其他原因') && !this.abnormalData.remark) || (this.abnormalData.reason.includes('故障抢修') && !this.abnormalData.remark) || (this.abnormalData.reason.includes('系统待机时间不准') && !this.abnormalData.remark)) {
        this.$message({type:'warning', message:this.$t('Please_fill_remarks')})
        return
      }
      this.$refs.abnormalRules.validate((v) => {
        if (v) {
          this.abnormalData.startTime = this.dateFmtYear(this.abnormalData.startTime)
          this.abnormalData.endTime = this.dateFmtYear(this.abnormalData.endTime)
          this.abnormalData.actualTime = String(this.abnormalData.actualTime)
          this.abnormalData.reasonList = this.ReasonReporting
          this.addUserBtnLoading = true;
          API.SubmissionCreate(this.abnormalData)
              .then((response) => {
                if (response.message.success) {
                  this.addUserBtnLoading = false;
                  this.$message({
                    message: response.message.message,
                    // showClose: true,
                    type: 'success',
                  });
                  this.abnormalShow = false;
                  this.getAbnormal();
                } else if (!response.message.success) {
                  this.addUserBtnLoading = false;
                  // this.$message({
                  //   message: response.message.message,
                  //   // showClose: true,
                  //   type: 'error',
                  // });
                }
              })
              .catch(() => {
                this.addUserBtnLoading = false;
              });
        } else {
          return false;
        }
        return v;
      });
    },
    addFaultBtn() {
      this.$refs.faultRules.validate((v) => {
        if (v) {
          this.addUserBtnLoading = true;
          const formdata = new FormData();
          formdata.append('repairType', this.faultDate.project)
          formdata.append('orderType', "1")
          if (this.faultDate.userId === undefined) {
            this.faultDate.userId = ''
            formdata.append('userId', this.faultDate.userId)
          } else {
            formdata.append('userId', this.faultDate.userId)
          }

          formdata.append('deviceId', this.faultDate.deviceId)
          formdata.append('repairInfo', this.faultDate.repairInfo)
          this.pics.forEach((pic, index) => {
            formdata.append(`pic${index + 1}`, base64toFile(pic))
          });
          if (this.video) {
            formdata.append('vid', base64toFile(this.video));
          }
          API.submitBaoxiu(formdata)
              .then((response) => {
                if (response.message.success) {
                  this.addUserBtnLoading = false;
                  this.$message({
                    message: response.message.message,
                    // showClose: true,
                    type: 'success',
                  });
                  this.faultShow = false;
                  this.getFault();
                } else if (!response.message.success) {
                  this.addUserBtnLoading = false;
                }
              })
              .catch(() => {
                this.addUserBtnLoading = false;
              });
        } else {
          return false;
        }
        return v;
      });
    },
    handlePersonSelect(row) {
      this.faultDate.userId = row.id
      this.faultDate.name = row.cName
      this.showPerson = false
    },
    goHistory () {
      this.historyList = []
      API.getHistoryMantainList({count: 100,page: 0,printTypeId: this.faultDate.deviceId}).then(res => {
        console.log(res.message.data.list)
        this.historyList.push(...res.message.data.list)
      })
      this.historyShow = true

      // const id = this.faultDate.deviceId;
      // this.$router.push(`/history-mantain-list/${id}`);

    },
    back() {
      this.$router.go(-1);
    },
    removeImage (index) {
      this.pics.splice(index, 1);
    },
    pickImage () {
      let input = this.$refs.imgInput
      if (input.files.length + this.pics.length > 3) {
        this.$message({
        message: this.$t('most_uploaded_img'),
        // showClose: true,
        type: 'warning',
      });
        return;
      }
      for (let i = 0; i < input.files.length; i++) {
        if(input.files[i]) {
          //在此限制图片的大小
          let imgSize = input.files[i].size;
          //35160  计算机存储数据最为常用的单位是字节(B)
          //在此处我们限制图片大小为2M
          if(imgSize>50*1024*1024){
            this.$message({
              message: this.$t('uploaded_image_large50M_please_reselect'),
              // showClose: true,
              type: 'warning',
            });
            return;
          }
          let reader = new FileReader
          reader.onload = e => {
            this.pics.push(e.target.result)
          }
          reader.readAsDataURL(input.files[i])
        }
      }
      this.$refs.imgInput.value = null;
    },
    selectVideo () {
      this.$refs.videoInput.click()
    },
    removeVideo () {
      this.video = null;
      this.videoSrc = null;
    },
    //播放视频
    playVideo () {
      this.showVideo = true;
    },
    selectImage () {
      this.$refs.imgInput.click()
    },
    pickVideo () {
      let input = this.$refs.videoInput
      if (input.files.length > 0) {
        //在此限制图片的大小
        let videoSize = input.files[0].size;
        //35160  计算机存储数据最为常用的单位是字节(B)
        //在此处我们限制图片大小为2M
        if(videoSize>50*1024*1024){
          this.$message({
            message: this.$t('uploaded_video_large50M_please_reselect'),
            // showClose: true,
            type: 'warning',
          });
          return;
        }
        this.video = null
        this.videoSrc = null
        let reader = new FileReader
        reader.onload = e => {
          this.video = e.target.result;
          this.videoSrc = e.target.result;
        }
        reader.readAsDataURL(input.files[0])
      }
      this.$refs.videoInput.value = null
    },
    handlePerson() {
      this.teamList = []
      API.teamList({type:3}).then(res => {
        this.teamList.push(...res.message.data)
      })
      this.showPerson = true
    },
    getDeviceListById(tab) {
      // this.replacement.page = 1
      // this.replacement.count = 10
      // this.abnormalForm.page = 1
      // this.abnormalForm.count = 10
      // this.faultForm.page = 1
      // this.faultForm.count = 10
      this.tabPaneTab = tab.label
      this.activeName = tab.name
      // if (tab.name === 'second') {
      //   this.replacement.page = 1
      //   this.replacement.type = 1
      //   this.replacement.count = 10
      //   this.getTeamUser();
      // } else if (tab.name === 'three'){
      //   this.replacement.page = 1
      //   this.replacement.type = 2
      //   this.replacement.count = 10
      //   this.getTeamUser();
      // }
      //
      // console.log(tab.name)
    },
    getDeviceListById2() {

    },
    getDeviceListById3() {

    },
    handleSizeChange(val) {
      this.replacement.count = val;
      this.getTeamUser();
    },
    handleCurrentChange(val) {
      this.replacement.page = val ;
      this.getTeamUser();
    },
    handleSizeChange2(val) {
      this.abnormalForm.count = val;
      this.getAbnormal();
    },
    handleCurrentChange2(val) {
      this.abnormalForm.page = val ;
      this.getAbnormal();
    },
    handleSizeChange3(val) {
      this.faultForm.count = val;
      this.getFault();
    },
    handleCurrentChange3(val) {
      this.faultForm.page = val ;
      this.getFault();
    },
  },
};
</script>
<style scoped lang="scss">
.table-box {
  position: relative;
}
::v-deep .dialog-footer {
  text-align: right;
}
::v-deep .el-tabs__active-bar {
  height: 2px;
  background-color: #F3A100;
}
.control-item {

}
.control-btn {
  text-align: right;
  position: absolute;
  right: 0px;
  top: -0px;
  z-index: 9999;
}
.control-box1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  position: absolute;
  right: 21px;
  top: 127px;
  z-index: 999;
}
.btn {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dcdfe6;
  width: 100%;
  border-radius: 4px;
  line-height: 1;
  height: 40px;
  padding: 12px 10px 0px 15px;
  color: #cccfd6;
  cursor: pointer;
}
.person {
  margin-bottom: 20px;
}
.history {
  text-align: right;
}
.record {
  margin-bottom: 0px;
  img {
    width: 15px;
    margin-right: 10px;
  }
  span{
    //display: inline-block;
    text-align: right;
    width: 100%;
    cursor: pointer;
  }
}
.pic-and-video-wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .btn {
    width: 90px;
    height: 90px;
    margin-top: 10px;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border: 0;
    padding: 0;
    img {
      width: 20px;
      height: 20px;
    }

    span {
      margin-top: 5px;
      font-size: 13px;
      color: grey;
    }
    .closeVideo-btn {
      margin-top: 8px;
    }
  }
}

.preview {
  width: 90px;
  height: 90px;
  margin-top: 10px;
  margin-right: 10px;
  position: relative;

  img {
    position: absolute;
    width: 90px;
    height: 90px;
  }

  .van-btton {
    position: absolute;
    right: 0;
    font-size: 20px;
    //top: -15px;
    background: #fff;
    padding: 0;
    color: #f56c6c;
  }
}

.voice {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: #f1f1f1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 22px;
    height: 22px;
  }
}

.video-label {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: lightgrey;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  font-size: 13px;
  color: grey;

  .close-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
.item {
  padding:8px 10px;
  img{
    width: 32px;
    margin-right: 10px;
  }
}
.item:hover {
  background-color: #FFFAED;
}
.list-item {
  display: flex;
  justify-content: space-between;
  color: #9F9F9F;
  font-size: 14px;
  .name {
    color: #3D3D3D;
    font-size: 16px;
    margin-bottom: 10px;
  }
  padding: 15px 0;
  border-bottom: 1px solid #F1F1F1;
}
.item-left {
  display: flex;
  img{
    width: 56px;
    margin-right: 15px;
  }


}
::v-deep .el-tabs__item {
  line-height: 1.5;
}
</style>

